import { GatsbySeo } from 'gatsby-plugin-next-seo'
import AssessoriaImprensa from 'src/components/servicos/ServicesPage/AssessoriaImprensa'

function Page() {
  return (
    <>
      <GatsbySeo title="Assessoria de Imprensa | Decathlon" noindex nofollow />
      <AssessoriaImprensa />
    </>
  )
}

export default Page
